// #fmpfp_nav_bar {
//   width: 100%;
//   height: 97px;
//   background-image: url("../images/top\ navigation.png");
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: center;
//   margin-bottom: 100px;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;

//   #fmpfp_nav_bar_col_2 {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     margin-top: 10px;
//     margin-left: 50px;

//     * {
//       margin-bottom: 15px;
//     }
//   }

//   #fmpfp_nav_bar_col_3 {
//     display: flex;
//     margin-left: auto;

//     a {
//       text-decoration: none;
//     }
    
//     .fmpfp_nav_bar_col_3_text {
//       color: white;
//       transition: 0.5s;

//       &:hover {
//         cursor: pointer;
//         color: rgba(255, 255, 255, 0.624);
//       }

//       &:first-of-type {
//         margin-right: 50px;
//       }
//     }
//   }
// }

// @media only screen and (max-width: 560px) {
//   #fmpfp_nav_bar {
//     // margin-bottom: 100px;
//   }
// }


.fmpfp_nav {
  width: 100%;
  height: 100px;
  background-image: url("../images/top\ navigation.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  // margin-bottom: 100px;
  
  .fmpfp_nav_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;

    .fmpfp_nav_logo_container {
      padding-left: 40px;
      width: 30%;
      min-width: 150px;
      max-width: 200px;
      
      .fmpfp_nav_logo {
        width: 100%;
      }
    }

    .fmpfp_nav_pst_name {
      color: white;
      float: left;
      position: absolute;
      bottom: 5px;
      left: 40px;
      font-weight: bold;
      font-size: 15px;
      letter-spacing: 1px;
    }

    .fmpfp_hamburger_menu {
      width: 30px;
      padding-right: 40px;

      .fmpfp_hamburger_menu_line {
        width: 100%;
        height: 3px;
        background-color: white;
        margin-bottom: 5px;
        float: left;
        pointer-events: none;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .fmpfp_hamburger_menu_content {
      display: none;
      position: absolute;
      background-color: white;
      right: 0;
      top: 100px;
      border: 0.5px solid $lightGrey;
      border-top: none;
      z-index: 5;
      width: 300px;
      
      &#fmpfp_user_menu {
        max-height: 700px;
        overflow-y: auto;
      }

      &.fmpfp_hamburger_menu_content_open {
        display: block;
      }

      .fmpfp_hamburger_menu_item {
        height: 100px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.5s;
        pointer-events: all;
        border-top: 0.5px solid $lightGrey;        


        a, .fmpfp_hamburger_menu_text {
          text-decoration: none;
          color: $darkGrey;
          font-weight: bold;
          width: 100%;
          height: 100%;
          text-align: center;
          line-height: 100px;
        }

        .fmpfp_hamburger_menu_text {
          pointer-events: none;
        }

        &.fmpfp_hamburger_menu_item_disabled {
          pointer-events: none;
          opacity: 0.5;
        }

        &:hover {
          background-color: $darkWhite;
          cursor: pointer;
        }

        &:nth-of-type(2) {
          border-top: 0.5px solid $lightGrey;        
        }
      }
    }
  }

  
}