
@font-face {
    font-family: HelveticaNeueLTStd;
    src: url("./../fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-Roman.WOFF") format("woff");
}

@font-face {
    font-family: HelveticaNeueLTStd;
    src: url("./../fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-Bd.WOFF") format("woff");
    font-weight: bold;
}
@font-face {
    font-family: HelveticaNeueLTStd;
    src: url("./../fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-BdCn.WOFF") format("woff");
    font-weight: bold;
    font-stretch: condensed;
}
@font-face {
    font-family: HelveticaNeueLTStd;
    src: url("./../fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-Th.WOFF") format("woff");
    font-weight: 100;
}

@font-face {
    font-family: BigCaslon;
    src: url("./../fonts/big_caslon_reg.otf") format("opentype");
}

@font-face {
    font-family: BigCaslon;
    font-weight: bold;
    src: url("./../fonts/big_caslon_bold.otf") format("opentype");
}

$font_caslon: "BigCaslon";
$fontstack: HelveticaNeueLTStd ;
$helvetica_fallback: "Helvetica Neue", "Arial" ;

$dermblue: #5e93db;
$white: white;
$darkGrey: #5B6670;
$card_shadow: 0px 5px 14px rgba(0, 0, 0, 0.4);
$greyHover: #73818c;
$darkWhite: #F9FAFB;
$lightGrey: #d7d7d7;
$baseGrey: #909090;
