@import "variables";

.App {
  width: 100%;
  height: 100%;

    
  .fa-circle-notch {
    display: block;
    position: fixed; /* or absolute */
    top: 49%;
    left: 48%;
    font-size: 35px;
    color: $darkGrey;
  }

  .fmpfp_landing_page_inner_container {
    width: 100%;
    margin: 0 auto;
    position: relative;
    max-width: 1200px;
    margin-top: 100px;

    .fmpfp_face_template_concern_icons {
      width: 35px;
      height: 30px;
      margin: 10px 0;
    }
  
    .fmpfp_skin_goals_toggle {
      background: $dermblue;
      border: 1px solid $dermblue;
      padding: 10px;
      color: white;
      font-size: 12px;
      width: auto;
      font-weight: bold;
      text-align: center;
      transition: 0.5s;
      border-radius: 5px;
  
      &:hover {
        background-color: white;
        color: $dermblue;
        cursor: pointer;
      }
  
      &#fmpfp_app_toggle {
        position: absolute;
        right: 10px;
        top: -60px;
      }
    }

    #fmpfp_skin_goals {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      //background-color: white;
    
      .fmpfp_skin_goals_toggle {
        background: none;
        border: 1px solid $dermblue;
        padding: 10px;
        color: $dermblue;
        font-size: 12px;
        width: auto;
        font-weight: bold;
        text-align: center;
        transition: 0.5s;
        border-radius: 5px;
    
        &:hover {
          background-color: $dermblue;
          color: white;
          cursor: pointer;
        }
      }
    
      #fmpfp_skin_goals_title {
        font-size: 45px;
        color: #5b6670;
        font-weight: bold;
        margin-bottom: 20px;
      }
    
      #fmpfp_skin_goals_input_container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    
        #fmpfp_skin_goals_input {
          width: 450px; //make more accurate later\
          height: 30px;
          border: 1px solid #5b6670;
          border-top-style: hidden;
          border-right-style: hidden;
          border-left-style: hidden;
          border-bottom-style: groove;
          outline: 0;
          //background-color: #f8f8f8;
        }
    
        @keyframes quiet {
          25% {
            transform: scaleY(0.6);
          }
          50% {
            transform: scaleY(0.4);
          }
          75% {
            transform: scaleY(0.8);
          }
        }
    
        @keyframes normal {
          25% {
            transform: scaleY(1);
          }
          50% {
            transform: scaleY(0.4);
          }
          75% {
            transform: scaleY(0.6);
          }
        }
        @keyframes loud {
          25% {
            transform: scaleY(1);
          }
          50% {
            transform: scaleY(0.4);
          }
          75% {
            transform: scaleY(1.2);
          }
        }
    
        .boxContainer {
          display: flex;
          justify-content: space-between;
          height: 40px;
          --boxSize: 8px;
          --gutter: 4px;
          width: calc((var(--boxSize) + var(--gutter)) * 5);
          margin: 20px 0;
        }
    
        .box {
          transform: scaleY(0.4);
          height: 100%;
          width: var(--boxSize);
          background: $darkGrey;
          animation-duration: 1.2s;
          animation-timing-function: ease-in-out;
          animation-iteration-count: infinite;
          border-radius: 8px;
        }
    
        .box1 {
          animation-name: quiet;
        }
    
        .box2 {
          animation-name: normal;
        }
    
        .box3 {
          animation-name: quiet;
        }
    
        .box4 {
          animation-name: loud;
        }
    
        .box5 {
          animation-name: quiet;
        }
    
        #fmpfp_skin_goals_icon_container {
          display: flex;
          margin-top: 10px;
          align-items: center;
          .fmpfp_skin_goals_icon {
            width: 25px;
            height: 25px;
            margin-right: 15px;
    
            &:hover {
              cursor: pointer;
            }
          }
    
          .fmpfp_hide_icons {
            display: none;
          }
        }
      }
    
      #fmpfp_face_template_container {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 50px 0;
        position: relative;
    
        #fmpfp_face_template {
          width: 370px;
          max-width: 620px;
          z-index: -1;
        }
    
        #fmpfp_face_icon_container {
          display: flex;
          border: 1px solid #5b6670;
          height: auto;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 500px;
          margin: 20px 0;

          .arrow {
            border: solid $darkGrey;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 2px;
            width: 10px;
            height: 10px;
            transition: 0.5s;

            &:hover {
              cursor: pointer;
              border-color: $lightGrey;
            }
          }

          .right {
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
          }

          .left {
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
          }
    
          .draggable {
            cursor: -webkit-grab;
            cursor: grab;
          }
    
          #fmpfp_icon_title_pair {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px 20px;
            width: 60px;
            .fmpfp_face_template_concern_icons {
              width: 35px;
              height: 30px;
              margin: 10px 0;
            }
    
            .fmpfp_icon_title {
              color: #5b6670;
              font-size: 12px;
              padding: 0;
              margin: 0;
              text-align: center;
            }
          }
        }

        #fmpfp_face_icon_page_number_container {
          display: flex;
          justify-content: center;

          .fmpfp_face_icon_page_number {
            margin: 0 10px;
            color: $dermblue;
            transition: 0.5s;

            &:hover {
              color: $darkGrey;
              cursor: pointer;
            }

            &.fmp_face_icon_page_number_selected {
              color: $darkGrey;
            }
          }
        }
        // .fmpfp_face_template_concern_icons {
        //     width: 60px;
        // }
      }
    }
  }  
}
 


@media only screen and (max-width: 800px) {
  .App {
    .fmpfp_landing_page_inner_container {
      .fa-circle-notch {
      }
  
      .fmpfp_skin_goals_toggle {
        background: transparent;
        border: 1px solid $dermblue;
        color: $dermblue;
        width: 70%;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
  
        &:hover {
          background: $dermblue;
          border: 1px solid $dermblue;
          padding: 10px;
          color: white;
        }
  
        &#fmpfp_app_toggle {
          position: absolute;
        }
      }

      #fmpfp_skin_goals {
        #fmpfp_skin_goals_title {
          font-size: 35px;
        }
        #fmpfp_skin_goals_input_container {
          #fmpfp_skin_goals_input {
            width: 300px; //make more accurate later\
          }
    
          #fmpfp_skin_goals_icon_container {
            .fmpfp_skin_goals_icon {
              padding: 0 20px;
            }
          }
        }
    
        .fmpfp_face_template_concern_icons {
          width: 40px;
          height: 35px;
        }
    
        #fmpfp_face_template_container {
          #fmpfp_face_template {
            width: 350px;
            max-width: 620px;
          }
    
          #fmpfp_face_icon_container {
            width: 360px;
            max-width: 380px;

            .arrow {
              padding: 1px;
            }
  
            .right {

            }
  
            .left {

            }

            #fmpfp_icon_title_pair {
              .fmpfp_face_template_concern_icons {
                width: 40px;
                height: 35px;
              }
    
              .fmpfp_icon_title {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}
