.fmpfp_generate_pre_auth {
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: center;
    right: 2%;
    top: 145px;

    #fmpfp_generate_pre_auth_btn {
        background: none;
        border: 1px solid $dermblue;
        padding: 10px;
        color: $dermblue;
        font-size: 12px;
        width: auto;
        font-weight: bold;
        text-align: center;
        transition: 0.5s;
        border-radius: 5px;
    
        &:hover {
          background-color: $dermblue;
          color: white;
          cursor: pointer;
        }
    }

    .fmpfp_generate_pre_auth_outer_container {
        width: 100vw;
        height: 200vh;
        background-color: #000;
        opacity: 0.5;
        position: fixed;
        top: 50%;
        left: 50%;
        /* bring your own prefixes */
        transform: translate(-50%, -50%);
    }

    #fmpfp_generate_pre_auth_container {
        background-color: white;
        opacity: 1;
        z-index: 10;
        position: fixed;
        top: 50%;
        left: 50%;
        /* bring your own prefixes */
        transform: translate(-50%, -50%);
        height: auto;
        width: 60%;
        max-width: 600px;
        min-width: 500px;

        display: flex;
        flex-direction: column;

        #fmpfp_generate_pre_auth_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 20%;
            border-bottom: 0.5px solid $lightGrey;
            padding: 5%;

            #fmpfp_generate_pre_auth_title {
                font-size: 21px;
                color: $darkGrey;
                display: inline;
                margin-top: 2px;
            }
            #fmpfp_generate_pre_auth_exit {
                background: transparent;
                border: none;
                font-size: 22px;
                font-weight: bold;
                color: $baseGrey;
                width: 25px;
                height: 25px;
                padding: 0;
                transition: 0.3s;

                &:hover {
                    cursor: pointer;
                    color: $greyHover;
                }
            }
        }
        #fmpfp_generate_pre_auth_content {
            height: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 10% 3%;
            margin-bottom: 50px;
            position: relative;


            .fmpfp_generate_pre_auth_prompt {
                font-size: 14px;
                margin-bottom: 5px;
                color: $darkGrey;
            }

            .fmpfp_generate_pre_auth_p {
                display: flex;
                flex-direction: column;
                width: 100%;

                .fmpfp_generate_pre_auth_content_header {
                    position: absolute;
                    top: 15px;
                    font-size: 16px;
                    color: $dermblue;
                    text-decoration: underline;
                }

                .fmpfp_generate_pre_auth_input {
                    width: 100%;
                    resize: vertical;
                    border: 0.8px solid $lightGrey;
                }

                #fmpfp_generate_pre_auth_complete_title {
                    text-align: center;
                    font-size: 18px;
                    margin: 10px 0 40px 0;
                    color: $dermblue;
                }
            }

            .fmpfp_generate_pre_auth_toggle_btn_container {
                position: absolute;
                right: 15px;
                bottom: -25px;

                .fmpfp_generate_pre_auth_toggle_btn {
                    border: none;
                    border-radius: 4px;
                    padding: .5rem 1.25rem;
                    transition: 0.5s;
                    text-transform: capitalize;
                    
                    &.fmpfp_pre_auth_btn_blue {
                        background-color: $dermblue;
                        color: white;
                        font-weight: bold;
                        margin-left: 10px;

                        &:hover {
                            background-color: #3d7dd4;
                            border-color: #3d7dd4;
                        }
                    }

                    &.fmpfp_pre_auth_btn_clear {
                        background-color: transparent;
                        border: 1px solid $dermblue;
                        color: $dermblue;

                        &:hover {
                            color: white;
                            border-color: transparent;
                            background-color: $dermblue;
                        }
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: 600px) {
    .fmpfp_generate_pre_auth {
        width: 80%;
        right: 10%;
        #fmpfp_generate_pre_auth_btn {
            width: 100%;
        }
    }
    
}