@import 'skin_goals';
@import 'consultation_form';
@import 'nav_bar';
@import 'variables';
@import 'login_page';
@import 'consult_sess_page';
@import 'display_session';
@import 'loading_roller';
@import 'treatment_auth_form';
@import 'generate_pre_auth';

* {
    margin: 0;
    padding: 0;
}

input, select{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.App {
    font-family: $font_caslon;
    //background-color: #f8f8f8;
    width: 100%;
    overflow: hidden;
}


* {
    font-family: HelveticaNeueLTStd;
}

.fmpfp_display_session_container {
    padding: 2%;
}

.fmpfp_login_failed_container {
    position: absolute;
    top: 5%;
    right: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $dermblue;
    padding: 17px;
    background-color: white;
    opacity: 1;

    p {
        font-size: 14px;

    }
}


.fmpfp_hidden {
    display: none;
}

.fmpfp_form_submit_success {
    width: 200px;
    height: 30px;
    position: absolute;
    background-color: white;
    top: 25px;
    right: 20px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.8px solid $dermblue;

    #fmpfp_form_submit_success_text {
        color: black;
        font-size: 12px;
        text-align: center;
    }
}