.fmpfp_consult_sess_page {
    width: 100%;
    height: 100%;
    .fmpfp_consultation_sess_pin_outer_container {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $darkGrey;

        .fmpfp_login_failed_container {
            top: 20%;
            right: 3%;
        }

        .fmpfp_consultation_sess_pin_inner_container {
            width: 40%;
            max-width: 500px;
            min-width: 300px;
            height: 200px;
            background-color: white;
            border: 0.7px solid $darkGrey;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .fmpfp_consultation_sess_content {
                display: flex;
                flex-direction: column;
                width: 50%;

                * {
                    letter-spacing: 1px;
                }

                #fmpfp_consultation_sess_pin_text {
                    color: $darkGrey;
                    font-size: 15px;
                    font-weight: bold;
                    margin-bottom: 5px;
                }

                #fmpfp_consultation_pin_input {
                    width: 100%;
                    height: 30px;
                    border: 1px solid $darkGrey;
                    margin-bottom: 15px;
                    padding-left: 5px;
                }

                #fmpfp_consultation_pin_submit {
                    width: 60%;
                    height: 40px;
                    background: $darkGrey;
                    border: none;
                    transition: 0.5s;

                    &.fmpfp_consultation_pin_submit_disabled {
                        background-color: #aeaeae;

                        &:hover {
                            cursor: initial;
                        }
                    }

                    &:hover {
                        cursor: pointer;
                    }

                    p {
                        line-height: 0;
                        // transform: translateY(-50%);
                        color: white;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    .fmpfp_consult_sess_page_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 100px;
        
        .fmpfp_sess_skin_goals_container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            #fmpfp_skin_goals_title {
                font-size: 45px;
                color: #5b6670;
                font-weight: bold;
                margin-bottom: 20px;
              }
    
            .fmpfp_sess_skin_goals_text {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 20px;
            }
    
            .fmpfp_sess_skin_goals_img {
                width: 100%;
                max-width: 600px;
            }

            .fmpfp_sess_skin_goals_desc {
                font-size: 18px;
                margin-top: 60px;
                color: #5b66707d;
            }
        }
    }

    .fmpfp_consult_sess_err_container {
        width: 100vw;
        height: 100vh;
        background-color: #5b66708d;
        z-index: 5;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        #fmpfp_consult_sess_err_modal {
            background-color: white;
            width: 500px;
            height: 20%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            #fmpfp_consult_sess_err_msg {
                color: #5b6670;
            }
            
            a {
                width: 50%;
                
                #fmpfp_consult_sess_err_btn {
                    margin-top: 20px;
                    width: 100%;
                    height: 60px;
                    border: none;
                    background-color: #5b6670;
                    color: white;
                    font-weight: bold;
                    letter-spacing: 1px;
                    transition: 0.5s;

                    &:hover {
                        background-color: #727a81;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .fmpfp_consult_sess_page {
        .fmpfp_consult_sess_err_container {
            #fmpfp_consult_sess_err_modal {
                width: 80%;
                padding: 2%;

                #fmpfp_consult_sess_err_msg {
                    color: #5b6670;
                    text-align: center;
                }
                
                a {
                    width: 80%;
                    
                    #fmpfp_consult_sess_err_btn {    
                        &:hover {
                            
                        }
                    }
                }
            }
        }
    }
}
