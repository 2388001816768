// @import 'variables';

 #fmpfp_consultation_container {

    .fmpfp_consultation_email_btn {
        width: 50%;
        border: 1px solid $dermblue;
        padding: 10px;
        margin: 0 auto;
        border-radius: 5px;
        transition: 0.5s;
        background-color: transparent;

        .fmpfp_consultation_email_btn_text {
            font-weight: 500;
            font-size: 13px;
            transition: 0.5s;
            color: $dermblue;
            text-align: center;
            background-color: unset;
            font-weight: bold;
            pointer-events: none;
        }

        &:hover {
            background-color: $dermblue;
            cursor: pointer;
            .fmpfp_consultation_email_btn_text {
                color: white;
            }
        }

        &.fmpfp_consultation_email_btn_disabled {
            pointer-events: none;
            border-color: $lightGrey;

            .fmpfp_consultation_email_btn_text {
                color: $darkGrey;
            }
        }
    }

    .fmpfp_consultation_complete_text {
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 30px;
    }

    .fmpfp_consultation_roller {
        margin: 0 auto;
    }

    * {}
    margin-bottom: 5%;
    
    * {
        // font-family: $font_caslon;
        background-color: white;
        color: $darkGrey;
    }

    input, textarea, canvas {
        min-width: 0px;
    }
    .sd-root-modern {
        .sd-container-modern__title {
            .sd-header__text {
                .sd-title {
                    color: $darkGrey;
                    box-shadow: 0px 2px 0px var(--primary, $dermblue);
                    padding-bottom: 2%;
                }
            }
        }

        .sd-question__erbox--outside-question {
            color: red;
        }

        .sd-navigation__complete-btn {
            background-color: $dermblue;
            color: white;
            
            &:hover {
                border: 1px solid #5e93db;
            }
        }
    }

    .sd-html a {
        color: #5b6670;
    }

    .sd-page {
        padding-top: 0;
    }
    .sd-navigation__prev-btn, .sd-navigation__next-btn, .sd-navigation__preview-btn {
        display: none;
    }
    .sd-input:focus {
        box-shadow: 0 0 0 2px var(--primary, $dermblue);
    }
    .sd-item__control:focus + .sd-item__decorator, .sd-item--allowhover:hover .sd-item__decorator {
        box-shadow: 0 0 0 2px var(--primary, $dermblue);
    }

    .sd-btn:not(:disabled):hover, .sd-btn:not(:disabled):focus {
        box-shadow: 0 0 0 2px var(--primary, $dermblue);
    }
    .sd-item--checked .sd-item__decorator {
        background: $dermblue;
    }

    .sd-checkbox--checked .sd-checkbox__svg {
        background: $dermblue;
        color: white;
    }
    .sd-checkbox--checked .sd-checkbox__control:focus + .sd-checkbox__decorator .sd-checkbox__svg use{
        fill: var(--primary, #ffffff);
    }

    .sd-input.sd-dropdown:focus-within{
        box-shadow: 0 0 0 2px var(--primary, $dermblue);
    }

    .sd-image {
        display: flex;
        justify-content: center;
        
        // img {
        //     width: 1000px;
        // }
        .sd-image__image--adaptive {
            max-width: 850px;
        }
    }
    .sd-paneldynamic__add-btn, .sd-action--negative {
        span {
            color: $dermblue;
        }
    }
    .sd-radio--checked .sd-radio__control:focus + .sd-radio__decorator::after {
        background-color: var(--primary, white);
    }
    .sd-panel__title {
        text-align: center;
        padding: 10px 0;
        span {
            font-size: 30px;
            background-color: transparent;
            line-height: 45px;
        }
    }

    input, textarea, .sd-signaturepad {
        // max-width: 500px;
        background-color: transparent;
    }

    .sd-question__content--left {
        max-width: 500px;
    }
    .sd-question__erbox {
        .sv-string-viewer {
            color: $dermblue;
        }
    }

    .sd-item__decorator {
        &:hover {
            cursor: pointer;
        }
    }

    .sv-list__item.sv-list__item--selected .sv-list__item-body {
        background-color: transparent !important;
    }

    li.sv-list__item--selected {
        div {
            background-color: $dermblue;
        }
    }

    #sq_2481_ariaTitle .sv-string-viewer {
        font-size: 35px;
    }

    .sd-paneldynamic__header {
        .sd-title {
            text-align: center;

            .sv-string-viewer {
                font-size: 30px;
            }
        }
    }
}

@media only screen and (max-width: 560px) {
    #fmpfp_consultation_container {
        .sd-root-modern {
            .sd-container-modern__title {
                .sd-header__text {
                    .sd-title {

                    }
                }
            }

            .sd-question__erbox--outside-question {

            }

            .sd-navigation__complete-btn {

            }
        }

        .sd-page {

        }
        .sd-navigation__prev-btn, .sd-navigation__next-btn, .sd-navigation__preview-btn {

        }
        .sd-input:focus {

        }
        .sd-item__control:focus + .sd-item__decorator, .sd-item--allowhover:hover .sd-item__decorator {

        }

        .sd-btn:not(:disabled):hover, .sd-btn:not(:disabled):focus {

        }
        .sd-item--checked .sd-item__decorator {

        }

        .sd-checkbox--checked .sd-checkbox__svg {

        }
        .sd-checkbox--checked .sd-checkbox__control:focus + .sd-checkbox__decorator .sd-checkbox__svg use{

        }

        .sd-input.sd-dropdown:focus-within{

        }

        .sd-image {

            .sd-image__image--adaptive {

            }
        }
        .sd-paneldynamic__add-btn, .sd-action--negative {
            span {

            }
        }
        .sd-radio--checked .sd-radio__control:focus + .sd-radio__decorator::after {

        }
        .sd-panel__title {

            span {
                font-size: 30px;
            }
        }
        .sd-row--multiple > div > .sd-questio {
            width: 200px;
        }

        input, textarea, .sd-signaturepad {
            width: 70%;
        }

        .sd-signaturepad {
            margin: 0 auto;
        }

        .sd-question--left {
            flex-direction: column;
        }

        .sd-question__content--left {
            padding: 10px 0;
            width: 70%;
            max-width: 500px;
        }
    }
}